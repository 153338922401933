

























































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mdiRefresh, mdiClose, mdiMagnify, mdiPlaylistPlus } from '@mdi/js';
import { getUserList, removeUserBySub } from '../../api/admin';
import { noop } from 'vue-class-component/lib/util';
import { getModule } from 'vuex-module-decorators';
import { SettingsModule } from '../../plugins/store';
import { Hub } from '@aws-amplify/core';
import type { CognitoUser } from '../../typings';
import UserInviteComponent from '../../components/UserInviteComponent.vue';
import { ADMIN_USER_GROUP, SUPPORT_USER_GROUP } from '../../constants';
import Auth from '@aws-amplify/auth';

const settingsStore: SettingsModule = getModule(SettingsModule);

interface ListItem {
  text: string;
  subtitle: string;
  link: string;
  sub: string;
}

@Component({
  components: {
    UserInviteComponent
  }
})
export default class UserListView extends Vue {
  private readonly ADMIN_USER_GROUP: typeof ADMIN_USER_GROUP =
    ADMIN_USER_GROUP;
  private readonly SUPPORT_USER_GROUP: typeof SUPPORT_USER_GROUP =
    SUPPORT_USER_GROUP;
  private readonly mdiRefresh: string = mdiRefresh;
  private readonly mdiPlaylistPlus: string = mdiPlaylistPlus;
  private readonly mdiClose: string = mdiClose;
  private readonly mdiMagnify: string = mdiMagnify;
  private readonly noop: typeof noop = noop;

  private loading: boolean = true;
  private showDialog: boolean = false;
  private removeAccount: ListItem | null = null;
  private items: Array<ListItem> = [];
  private userGroups: string[] = [];

  private get showConsole(): boolean {
    return settingsStore.console;
  }

  private get filteredItems(): ListItem[] {
    return this.items.filter(
      (item: ListItem): boolean =>
        !this.searchList ||
        JSON.stringify(item)
          .toUpperCase()
          .includes(this.searchList.toUpperCase())
    );
  }

  @Prop({ type: String, default: '' })
  private readonly searchList!: string;

  private async created(): Promise<void> {
    this.userGroups =
      (
        await Auth.currentSession().catch((): undefined => undefined)
      )?.getIdToken()?.payload?.['cognito:groups'] || [];
  }

  private mounted(): void {
    this.getUserList();
  }

  private showError(message: string): void {
    Hub.dispatch('appAlert', {
      event: 'error',
      message
    });
  }

  private getUserList(): void {
    this.loading = true;
    getUserList()
      .then(
        (items: Array<CognitoUser>): void =>
          void (this.items = items
            .map(
              (item: CognitoUser): ListItem => ({
                text: item.email,
                subtitle: `$vuetify.users.${
                  item.userGroup === ADMIN_USER_GROUP
                    ? 'ADMIN'
                    : item.userGroup === SUPPORT_USER_GROUP
                    ? 'SUPPORT'
                    : 'REGULAR_USER'
                }`,
                link: `/users/${item.sub}`,
                sub: item.sub
              })
            )
            .sort())
      )
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private removeUser(): void {
    if (!this.removeAccount) {
      return;
    }
    const sub: string = this.removeAccount.sub;
    this.loading = true;
    this.removeAccount = null;
    removeUserBySub(sub)
      .then((): void => {
        this.getUserList();
        this.$router.replace('/users').catch(noop);
      })
      .catch((error: Error): void => {
        this.loading = false;
        this.showError(error.message);
      });
  }
}
