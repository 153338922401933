


























































































































































































import type {
  UserInvitation,
  ValidationRule,
  ValidationRules
} from '../typings';
import { Vue, Component, Watch } from 'vue-property-decorator';
import XLSX from 'xlsx';
import { Hub } from '@aws-amplify/core';
import { inviteUsers } from '../api/admin';

@Component
export default class UserInviteComponent extends Vue {
  private readonly emailPattern: string =
    '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
  private readonly requiredRule: ValidationRule = (
    value: string
  ): boolean | string => !!value || '$vuetify.invite.REQUIRED';
  private readonly emailRules: ValidationRules = [
    (value: string): boolean | string =>
      !value || value.length <= 320 || '$vuetify.invite.TOO_LONG',
    (value: string): boolean | string =>
      !value ||
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ||
      '$vuetify.invite.INVALID_MAIL_ADDRESS'
  ];
  private readonly sizeRule: ValidationRule = (v: File): true | string =>
    !v || v.size < 10 * 1024 * 1024 || '$vuetify.firmware.TOO_LARGE';

  private loading: boolean = false;
  private singleValid: boolean = false;
  private multipleValid: boolean = false;
  private tab: number = 0;
  private singleFirstname: string = '';
  private singleLastname: string = '';
  private singleEmail: string = '';
  private multipleFile: File | null = null;
  private multipleWorkbook: XLSX.WorkBook | null = null;
  private multipleSheet: string | null = null;
  private multipleFirstnameColumn: string | null = null;
  private multipleLastnameColumn: string | null = null;
  private multipleEmailColumn: string | null = null;
  private multipleSheets: string[] = [];
  private multipleColumns: string[] = [];

  @Watch('multipleFile', {
    deep: true
  })
  private async onFileChange(file: File): Promise<void> {
    try {
      this.multipleWorkbook = XLSX.read(await file.arrayBuffer(), {
        type: 'buffer'
      });
      this.multipleSheets = this.multipleWorkbook.SheetNames || [];
    } catch (e) {
      this.multipleWorkbook = null;
      this.multipleSheets = [];
      this.multipleSheet = null;
    }
  }

  @Watch('multipleSheet')
  private async onSheetChange(sheet: string): Promise<void> {
    try {
      this.multipleColumns =
        XLSX.utils.sheet_to_json<string[]>(
          this.multipleWorkbook?.Sheets?.[sheet] as XLSX.Sheet,
          {
            header: 1
          }
        )[0] || [];
    } catch (e) {
      this.multipleFirstnameColumn = null;
      this.multipleLastnameColumn = null;
      this.multipleEmailColumn = null;
      this.multipleColumns = [];
    }
  }

  private showError(message: string): void {
    Hub.dispatch('appAlert', {
      event: 'error',
      message
    });
  }

  private reset(): void {
    this.loading = false;
    this.singleValid = false;
    this.multipleValid = false;
    this.singleFirstname = '';
    this.singleLastname = '';
    this.singleEmail = '';
    this.multipleFile = null;
    this.multipleWorkbook = null;
    this.multipleSheet = null;
    this.multipleFirstnameColumn = null;
    this.multipleLastnameColumn = null;
    this.multipleEmailColumn = null;
    this.multipleSheets = [];
    this.multipleColumns = [];
  }

  private getInvites(): void {
    let invites: UserInvitation[] = [];
    if (this.tab === 0 && this.singleValid) {
      invites.push({
        firstname: this.singleFirstname,
        lastname: this.singleLastname,
        email: this.singleEmail
      });
    } else if (this.tab === 1 && this.multipleValid) {
      try {
        for (const row of XLSX.utils.sheet_to_json<Record<string, string>>(
          this.multipleWorkbook?.Sheets?.[
            this.multipleSheet as string
          ] as XLSX.Sheet
        ) || []) {
          if (
            row[this.multipleFirstnameColumn as string] &&
            row[this.multipleLastnameColumn as string] &&
            new RegExp(this.emailPattern).test(
              row[this.multipleEmailColumn as string]
            )
          ) {
            invites.push({
              firstname: row[this.multipleFirstnameColumn as string],
              lastname: row[this.multipleLastnameColumn as string],
              email: row[this.multipleEmailColumn as string]
            });
          } else {
            // eslint-disable-next-line no-console
            console.error(row);
            throw new Error(
              this.$vuetify.lang.t('$vuetify.invite.PARSE_ERROR')
            );
          }
        }
      } catch (e) {
        this.showError(e.message);
        return;
      }
    } else {
      return;
    }
    this.inviteUsers(invites);
  }

  private cancel(): void {
    this.$emit('cancel');
    this.reset();
  }

  private inviteUsers(invites: UserInvitation[]): void {
    this.loading = true;
    inviteUsers(invites)
      .then(
        (result: {
          success: string[];
          resend: string[];
          error: string[];
        }): void => {
          const message: string = `${this.$vuetify.lang.t(
            '$vuetify.users.INVITE_SUCCESS'
          )}${result?.success?.length || 0} | ${this.$vuetify.lang.t(
            '$vuetify.users.INVITE_RESEND'
          )}${result?.resend?.length || 0} | ${this.$vuetify.lang.t(
            '$vuetify.users.INVITE_ERROR'
          )}${result?.error?.length || 0}${
            result?.error?.length ? ` --> ${result.error.join(', ')}` : ''
          }`;
          Hub.dispatch('appAlert', {
            event: result?.error?.length
              ? 'error'
              : result?.resend?.length
              ? 'warning'
              : 'success',
            message
          });
          if (!result?.error?.length) {
            this.reset();
            this.$emit('success');
          }
        }
      )
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }
}
