var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"flex-nowrap",style:(_vm.$vuetify.breakpoint.mdAndUp
      ? 'height: calc(100vh - 64px)'
      : 'height: 100%'),attrs:{"dense":"","no-gutters":"","align":"stretch","justify":"start"}},[_c('v-col',{staticStyle:{"max-width":"390px","height":"100%"}},[_c('v-list',{staticClass:"pa-0",staticStyle:{"height":"100%","position":"relative"}},[_c('v-subheader',{staticStyle:{"position":"relative","height":"85px"}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.users.USERS'))+" "),_c('v-spacer',{staticStyle:{"min-width":"24px"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.showDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiPlaylistPlus))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.users.INVITE_USERS'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.getUserList}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiRefresh))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.users.REFRESH'))+" ")])])],1),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","single-line":"","autofocus":"","label":_vm.$vuetify.lang.t('$vuetify.shadows.SEARCH'),"placeholder":_vm.$vuetify.lang.t('$vuetify.shadows.SEARCH'),"type":"search","value":_vm.searchList || ''},on:{"input":function($event){_vm.$router
                  .replace({
                    query: Object.assign({}, _vm.$route.query,
                      {search: ($event || '').trim() || undefined})
                  })
                  .catch(_vm.noop)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticStyle:{"margin":"6px","margin-bottom":"5px"},attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiMagnify)+" ")])]},proxy:true}])})],1)],1)],1),(_vm.loading)?_c('v-progress-circular',{staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%)"},attrs:{"indeterminate":"","color":"primary"}}):(!_vm.filteredItems.length)?_c('div',{staticClass:"pt-4",style:(("text-align: center;\n          color: " + (_vm.$vuetify.theme.dark
              ? 'rgba(255, 255, 255, 0.5)'
              : 'rgba(0, 0, 0, 0.38)') + ";\n          font-size: 0.875rem;"))},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.dataIterator.noResultsText'))+" ")]):_c('v-list-item-group',{style:(_vm.$vuetify.breakpoint.mdAndUp
            ? 'height: calc(100vh - 64px - 85px); position: relative; overflow: auto'
            : 'position: relative'),attrs:{"color":"primary"}},_vm._l((_vm.filteredItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.link + (_vm.searchList ? ("?search=" + _vm.searchList) : ''),"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.$vuetify.lang.t(item.subtitle))}})],1),(_vm.userGroups.includes(_vm.ADMIN_USER_GROUP))?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){$event.preventDefault();_vm.removeAccount = item}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.$route.path.includes(item.link) ? 'primary' : undefined}},[_vm._v(" "+_vm._s(_vm.mdiClose)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.users.REMOVE_USER'))+" ")])])],1):_vm._e()],1)}),1)],1)],1),_c('v-divider',{staticClass:"ma-0",attrs:{"vertical":""}}),_c('v-col',{style:(!_vm.$route.path.split('/')[2] ? 'max-width: 0' : '')},[_c('router-view',{key:_vm.$route.path.split('/')[2],on:{"refreshAdminState":_vm.getUserList}})],1),(
      _vm.showConsole && _vm.$vuetify.breakpoint.mdAndUp && _vm.$route.path.split('/')[2]
    )?_c('v-divider',{staticClass:"ma-0",staticStyle:{"right":"0","position":"sticky","z-index":"2"},attrs:{"vertical":""}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500"},on:{"click:outside":function($event){_vm.showDialog = false}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.showDialog)?_c('UserInviteComponent',{on:{"cancel":function($event){_vm.showDialog = false},"success":function($event){_vm.showDialog = false;
        _vm.getUserList();}}}):_vm._e()],1),_c('v-dialog',{attrs:{"value":!!_vm.removeAccount,"max-width":"400"},on:{"input":function($event){_vm.removeAccount = null}}},[(_vm.removeAccount)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.users.REMOVE_USER'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.removeAccount.text)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.users.CONFIRM_REMOVE_USER'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.removeAccount = null}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.users.CANCEL'))+" ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.removeUser}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.users.REMOVE'))+" ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }